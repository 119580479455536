import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from "@/views/Dashboard.vue";
import Requisites from "@/views/Requisites.vue";
import Deals from "@/views/Deals.vue";
import Settings from "@/views/Settings.vue";
import AccountOperations from "@/views/AccountOperations.vue";
import {UserRole} from "@/models/enum/UserRole";
import Invoices from "@/views/Invoices.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import Wallets from "@/views/Wallets.vue";
import Users from "@/views/Users.vue";
import Routing from "@/views/Routing.vue";
import WithdrawalRequests from "@/views/WithdrawalRequests.vue";
import InviteLogin from "@/views/InviteLogin.vue";
import OperatorTraders from "@/views/OperatorTraders.vue";
import RequisitesGroups from "@/views/RequisitesGroups.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.MERCHANT]},
    },
    {
        path: '/admin/accounts',
        name: 'adminDashboard',
        component: AdminDashboard,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/admin/wallets',
        name: 'wallets',
        component: Wallets,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/admin/users',
        name: 'users',
        component: Users,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/traders',
        name: 'traders',
        component: OperatorTraders,
        meta: {requiresAuth: true, roles: [UserRole.OPERATOR]},
    },
    {
        path: '/admin/routing',
        name: 'routing',
        component: Routing,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/admin/withdrawal-requests',
        name: 'withdrawalRequests',
        component: WithdrawalRequests,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN]},
    },
    {
        path: '/deals',
        name: 'deals',
        component: Deals,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.OPERATOR, UserRole.ADMIN]},
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: Invoices,
        meta: {requiresAuth: true, roles: [UserRole.MERCHANT]},
    },
    {
        path: '/account-operations',
        name: 'accountOperations',
        component: AccountOperations,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.MERCHANT, UserRole.ADMIN]},
    },
    {
        path: '/requisites',
        name: 'requisites',
        component: Requisites,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.ADMIN, UserRole.OPERATOR]},
    },
    {
        path: '/requisites-groups',
        name: 'requisitesGroups',
        component: RequisitesGroups,
        meta: {requiresAuth: true, roles: [UserRole.ADMIN, UserRole.OPERATOR]},
    },
    {
        path: '/settings',
        name: 'settings',
        component: Settings,
        meta: {requiresAuth: true, roles: [UserRole.TRADER, UserRole.OPERATOR, UserRole.MERCHANT, UserRole.ADMIN]},
    },
    {path: '/login', name: 'login', component: Login},
    {path: '/invite/:token', name: 'inviteLogin', component: InviteLogin},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router


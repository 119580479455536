import axios from "axios";
import TraderDTO from "@/models/TraderDTO";
import AccessTokenDTO from "@/models/AccessTokenDTO";
import AccountDTO from "@/models/AccountDTO";
import AccountOperationDTO from "@/models/AccountOperationDTO";
import PaginationDTO from "@/models/PaginationDTO";
import PaginatedResponseDTO from "@/models/PaginatedResponseDTO";
import qs from 'qs';
import TraderDealDTO from "@/models/TraderDealDTO";
import DealRequestDTO from "@/models/request/DealRequestDTO";
import RequisitesRequestDTO from "@/models/request/RequisitesRequestDTO";
import TraderRequisitesDTO from "@/models/TraderRequisitesDTO";
import CurrencyMethodsDTO from "@/models/CurrencyMethodsDTO";
import RequisitesUpdateRequestDTO from "@/models/request/RequisitesUpdateRequestDTO";
import UserDTO from "@/models/UserDTO";
import ExtendedDealDTO from "@/models/ExtendedDealDTO";
import MerchantDTO from "@/models/MerchantDTO";
import InvoiceDTO from "@/models/InvoiceDTO";
import InvoiceDealsRequestDTO from "@/models/request/InvoiceDealsRequestDTO";
import InvoiceDealDTO from "@/models/InvoiceDealDTO";
import TraderDeviceDTO from "@/models/TraderDeviceDTO";
import MerchantStoreDTO from "@/models/MerchantStoreDTO";
import MerchantStoreUpdateRequestDTO from "@/models/request/MerchantStoreUpdateRequestDTO";
import DepositDTO from "@/models/DepositDTO";
import WalletDTO from "@/models/WalletDTO";
import TraderAccountDTO from "@/models/TraderAccountDTO";
import MerchantStoreAccountDTO from "@/models/MerchantStoreAccountDTO";
import TradersUpdateRequestDTO from "@/models/request/TradersUpdateRequestDTO";
import TradersCreateRequestDTO from "@/models/request/TradersCreateRequestDTO";
import UserUpdateRequestDTO from "@/models/request/UserUpdateRequestDTO";
import UserCreateRequestDTO from "@/models/request/UserCreateRequestDTO";
import MerchantUpdateRequestDTO from "@/models/request/MerchantUpdateRequestDTO";
import MerchantCreateRequestDTO from "@/models/request/MerchantCreateRequestDTO";
import RoutingRuleDTO from "@/models/RoutingRuleDTO";
import RoutingRuleCreateRequestDTO from "@/models/request/RoutingRuleCreateRequestDTO";
import AccountWithdrawalRequestDTO from "@/models/request/AccountWithdrawalRequestDTO";
import WithdrawalDTO from "@/models/WithdrawalDTO";
import InviteRequestDTO from "@/models/InviteRequestDTO";
import InviteRequestUserRequestDTO from "@/models/request/InviteRequestUserRequestDTO";
import TraderRequisiteGroupDTO from "@/models/TraderRequisiteGroupDTO";
import DisputeRequestRequestDTO from "@/models/request/DisputeRequestRequestDTO";
import DisputeRequestDTO from "@/models/DisputeRequestDTO";
import TelegramCreateInviteResponseDTO from "@/models/TelegramCreateInviteResponseDTO";

export default class APIClient {

    baseUrl = () => {
        return axios.defaults.baseURL;
    }

    me = async (): Promise<UserDTO|TraderDTO|MerchantDTO> => {
        let resp = await axios({
            url: `/users/me`,
            method: 'GET',
        });

        return resp.data;
    }

    getInviteRequest = async (token: string): Promise<InviteRequestDTO> => {
        let resp = await axios({
            url: `/invite-requests/${token}`,
            method: 'GET',
        });

        return resp.data;
    }

    useInviteRequest = async (token: string, req: InviteRequestUserRequestDTO): Promise<AccessTokenDTO> => {
        let resp = await axios({
            url: `/invite-requests/${token}/use`,
            data: {
                ...req
            },
            method: 'POST',
        });

        return resp.data;
    }

    changeTraderStatus = async (isActive: boolean): Promise<any> => {
        return axios({
            url: `/traders/me/change-status`,
            method: 'PUT',
            data: {
                isActive
            },
        });
    }

    changePassword = async (plainPassword: string): Promise<any> => {
        return axios({
            url: `/users/change-password`,
            method: 'PUT',
            data: {
                plainPassword
            },
        });
    }

    devicesDownload = async (id: string): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/${id}/macrodroid`,
            method: 'GET',
        });

        return resp.data;
    }

    devicesRegisterDevice = async (id: string): Promise<any> => {
        let resp = await axios({
            url: `/trader-devices/${id}/register-device`,
            method: 'GET',
        });

        return resp.data;
    }

    createTgInviteRequest = async (): Promise<TelegramCreateInviteResponseDTO> => {
        let resp = await axios({
            url: `/telegram/invite-requests`,
            method: 'POST',
        });

        return resp.data as TelegramCreateInviteResponseDTO;
    }

    devicesMessageLogs = async (id: string, params: Object): Promise<PaginatedResponseDTO<any>> => {
        let resp = await axios({
            url: `/trader-devices/${id}/message-log`,
            method: 'GET',
            params: params,
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data;
    }

    storesAll = async (): Promise<MerchantStoreDTO[]> => {
        let resp = await axios({
            url: `/stores/all`,
            method: 'GET',
        });

        return resp.data as MerchantStoreDTO[];
    }

    updateStoreApiKey = async (id: number): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores/${id}/api-key`,
            method: 'PUT',
        })
        return resp.data as MerchantStoreDTO
    }

    deleteStore = async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/stores/${id}`,
            method: 'DELETE',
        })
    }

    updateStore = async (id: number, requestDTO: MerchantStoreUpdateRequestDTO): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores/${id}`,
            data: requestDTO,
            method: 'PUT',
        })
        return resp.data as MerchantStoreDTO
    }

    createStore = async (requestDTO: MerchantStoreUpdateRequestDTO): Promise<MerchantStoreDTO> => {
        let resp = await axios({
            url: `/stores`,
            data: requestDTO,
            method: 'POST',
        })
        return resp.data as MerchantStoreDTO
    }


    devicesAll = async (): Promise<TraderDeviceDTO[]> => {
        let resp = await axios({
            url: `/trader-devices/all`,
            method: 'GET',
        });

        return resp.data as TraderDeviceDTO[];
    }

    traderRequisiteGroupsAll = async (): Promise<TraderRequisiteGroupDTO[]> => {
        let resp = await axios({
            url: `/trader-requisite-groups/all`,
            method: 'GET',
        });

        return resp.data as TraderRequisiteGroupDTO[];
    }

    traderRequisiteGroups = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderRequisiteGroupDTO>> => {
        let resp = await axios({
            url: `/trader-requisite-groups`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<TraderRequisiteGroupDTO>;
    }

    devicesCreate = async (device: TraderDeviceDTO): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices`,
            data: device,
            method: 'POST',
        });

        return resp.data as TraderDeviceDTO
    }

    devicesUpdate = async (id: string, device: TraderDeviceDTO): Promise<TraderDeviceDTO> => {
        let resp = await axios({
            url: `/trader-devices/` + id,
            data: device,
            method: 'PUT',
        });

        return resp.data as TraderDeviceDTO
    }

    devicesDelete = async (id: string): Promise<void> => {
        let resp = await axios({
            url: `/trader-devices/` + id,
            method: 'DELETE',
        });
    }

    traderRequisiteGroupDelete = async (id: number): Promise<void> => {
        let resp = await axios({
            url: `/trader-requisite-groups/` + id,
            method: 'DELETE',
        });
    }

    traderRequisiteGroupToggleRequisites = async (id: number, mode: string): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups/${id}/requisites/${mode}`,
            method: 'POST',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    traderRequisiteGroupUpdate = async (id: number, group: TraderRequisiteGroupDTO): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups/` + id,
            data: group,
            method: 'PUT',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    traderRequisiteGroupCreate = async (device: TraderRequisiteGroupDTO): Promise<TraderRequisiteGroupDTO> => {
        let resp = await axios({
            url: `/trader-requisite-groups`,
            data: device,
            method: 'POST',
        });

        return resp.data as TraderRequisiteGroupDTO
    }

    accountsAll = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/accounts/all`,
            method: 'GET',
        });

        return resp.data as AccountDTO[];
    }

    traderAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/trader-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    merchantStoreAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/merchant-store-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    systemAccountsSummary = async (): Promise<AccountDTO[]> => {
        let resp = await axios({
            url: `/system-accounts/summary`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as AccountDTO[];
    }

    traderAccounts = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderAccountDTO>> => {
        let resp = await axios({
            url: `/trader-accounts`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            }
        });

        return resp.data as PaginatedResponseDTO<TraderAccountDTO>;
    }

    traders = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<TraderDTO>> => {
        let resp = await axios({
            url: `/traders`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderDTO>;
    }

    merchants = async (pagination: PaginationDTO): Promise<PaginatedResponseDTO<MerchantDTO>> => {
        let resp = await axios({
            url: `/merchants`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantDTO>;
    }

    merchantStores = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<MerchantStoreDTO>> => {
        let resp = await axios({
            url: `/stores`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantStoreDTO>;
    }

    routingRules = async (pagination: PaginationDTO): Promise<PaginatedResponseDTO<RoutingRuleDTO>> => {
        let resp = await axios({
            url: `/routing-rules`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<RoutingRuleDTO>;
    }


    routingRulesCreate = async (requestDTO: RoutingRuleCreateRequestDTO): Promise<RoutingRuleDTO> => {
        let resp = await axios({
            url: `/routing-rules`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as RoutingRuleDTO
    }

    routingRulesUpdate = async (id: number, requestDTO: RoutingRuleCreateRequestDTO): Promise<RoutingRuleDTO> => {
        let resp = await axios({
            url: `/routing-rules/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as RoutingRuleDTO
    }

    merchantsStoresAll = async (merchantId: number): Promise<MerchantStoreDTO[]> => {
        let resp = await axios({
            url: `/merchants/${merchantId}/stores/all`,
            method: 'GET',
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as MerchantStoreDTO[];
    }

    users = async (pagination: PaginationDTO, request: any): Promise<PaginatedResponseDTO<UserDTO>> => {
        let resp = await axios({
            url: `/users`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<UserDTO>;
    }

    wallets = async (pagination: PaginationDTO): Promise<PaginatedResponseDTO<WalletDTO>> => {
        let resp = await axios({
            url: `/wallets`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<WalletDTO>;
    }

    walletsSystemAll = async (): Promise<WalletDTO[]> => {
        let resp = await axios({
            url: `/wallets/system/all`,
            method: 'GET',
        });

        return resp.data as WalletDTO[];
    }

    merchantStoreAccounts = async (pagination: PaginationDTO, req: Object = {}): Promise<PaginatedResponseDTO<MerchantStoreAccountDTO>> => {
        let resp = await axios({
            url: `/merchant-store-accounts`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                ...req
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<MerchantStoreAccountDTO>;
    }

    invoiceDeal = async (id: string): Promise<InvoiceDealDTO> => {
        let resp = await axios({
            url: `/invoice-deals/${id}`,
            method: 'GET',
        });

        return resp.data as InvoiceDealDTO;
    }

    deposit = async (id: string): Promise<DepositDTO> => {
        let resp = await axios({
            url: `/deposits/${id}`,
            method: 'GET',
        });

        return resp.data as DepositDTO;
    }

    withdrawal = async (id: string): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}`,
            method: 'GET',
        });

        return resp.data as WithdrawalDTO;
    }

    processWithdrawal = async (id: string, request: Object): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/withdrawals/${id}/process`,
            data: {
                ...request
            },
            method: 'POST',
        });

        return resp.data as WithdrawalDTO;
    }

    accountWallet = async (id: string): Promise<WalletDTO> => {
        let resp = await axios({
            url: `/accounts/${id}/wallet`,
            method: 'GET',
        });

        return resp.data as WalletDTO;
    }

    invoiceDeals = async (pagination: PaginationDTO, orderBy: Object, request: InvoiceDealsRequestDTO): Promise<PaginatedResponseDTO<InvoiceDealDTO>> => {
        let resp = await axios({
            url: `/invoice-deals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<InvoiceDealDTO>;
    }

    deals = async (pagination: PaginationDTO, orderBy: Object, request: DealRequestDTO): Promise<PaginatedResponseDTO<TraderDealDTO|ExtendedDealDTO>> => {
        let resp = await axios({
            url: `/trader-deals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderDealDTO>;
    }

    disputeRequests = async (pagination: PaginationDTO, orderBy: Object, request: DisputeRequestRequestDTO): Promise<PaginatedResponseDTO<DisputeRequestDTO>> => {
        let resp = await axios({
            url: `/dispute-requests`,
            method: 'POST',
            headers: {'X-HTTP-METHOD-OVERRIDE': "GET"},
            data: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
        });

        return resp.data as PaginatedResponseDTO<DisputeRequestDTO>;
    }

    confirmDisputeRequests = async (id: string, req: Object = {}): Promise<DisputeRequestDTO> => {
        let resp = await axios({
            url: `/dispute-requests/${id}/confirm`,
            data: {
                ...req
            },
            method: 'PUT',
        });

        return resp.data as DisputeRequestDTO;
    }


    deal = async (id: string): Promise<TraderDealDTO> => {
        let resp = await axios({
            url: `/trader-deals/${id}`,
            method: 'GET',
        });

        return resp.data as TraderDealDTO;
    }

    dealChangeStatus = async (id: string, action: string, req: Object = {}): Promise<TraderDealDTO> => {
        let resp = await axios({
            url: `/trader-deals/${id}/${action}`,
            data: {
                ...req
            },
            method: 'PUT',
        });

        return resp.data as TraderDealDTO;
    }

    dealsCount = async (request: DealRequestDTO): Promise<number> => {
        let resp = await axios({
            url: `/trader-deals/count`,
            method: 'GET',
            params: {
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as number;
    }

    requisitesCurrencyMethods = async (): Promise<CurrencyMethodsDTO[]> =>  {
        let resp = await axios({
            url: `/requisites/currency-methods`,
            method: 'GET',
        })

        return resp.data as CurrencyMethodsDTO[];
    }

    requisites = async (pagination: PaginationDTO, orderBy: Object, request: RequisitesRequestDTO): Promise<PaginatedResponseDTO<TraderRequisitesDTO>> => {
        let resp = await axios({
            url: `/requisites`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<TraderRequisitesDTO>;
    }

    withdrawals = async (pagination: PaginationDTO): Promise<PaginatedResponseDTO<WithdrawalDTO>> => {
        let resp = await axios({
            url: `/withdrawals`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<WithdrawalDTO>;
    }

    accountOperations = async (pagination: PaginationDTO, orderBy: Object, request: Object): Promise<PaginatedResponseDTO<AccountOperationDTO>> => {
        let resp = await axios({
            url: `/account-operations`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request

            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<AccountOperationDTO>;
    }

    invoices = async (pagination: PaginationDTO, orderBy: Object, request: Object = {}): Promise<PaginatedResponseDTO<InvoiceDTO>> => {
        let resp = await axios({
            url: `/invoices`,
            method: 'GET',
            params: {
                limit: pagination.limit,
                offset: pagination.offset,
                orderBy: orderBy,
                ...request
            },
            paramsSerializer: params => {
                return qs.stringify(params)
            }
        });

        return resp.data as PaginatedResponseDTO<InvoiceDTO>;
    }

    updateRequisites = async (id: number, requestDTO: RequisitesUpdateRequestDTO): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    toggleRequisitesActivity = async (id: number, type: string): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites/${id}/${type}`,
            method: 'PUT',
        })

        return resp.data as TraderRequisitesDTO
    }

    tradersCreate = async (requestDTO: TradersCreateRequestDTO): Promise<TraderDTO> => {
        let resp = await axios({
            url: `/traders`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as TraderDTO
    }

    accountWithdrawalCreate = async (id: string, requestDTO: AccountWithdrawalRequestDTO): Promise<WithdrawalDTO> => {
        let resp = await axios({
            url: `/accounts/${id}/withdraw`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as WithdrawalDTO
    }

    tradersUpdate = async (id: number, requestDTO: TradersUpdateRequestDTO): Promise<TraderDTO> => {
        let resp = await axios({
            url: `/traders/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as TraderDTO
    }

    usersCreate = async (requestDTO: UserCreateRequestDTO): Promise<UserDTO> => {
        let resp = await axios({
            url: `/users`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as UserDTO
    }

    usersUpdate = async (id: number, requestDTO: UserUpdateRequestDTO): Promise<UserDTO> => {
        let resp = await axios({
            url: `/users/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as UserDTO
    }

    merchantsCreate = async (requestDTO: MerchantCreateRequestDTO): Promise<MerchantDTO> => {
        let resp = await axios({
            url: `/merchants`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as MerchantDTO
    }

    merchantsUpdate = async (id: number, requestDTO: MerchantUpdateRequestDTO): Promise<MerchantDTO> => {
        let resp = await axios({
            url: `/merchants/${id}`,
            data: requestDTO,
            method: 'PUT',
        })

        return resp.data as MerchantDTO
    }

    deleteRequisites = async (id: number): Promise<void> => {
        await axios({
            url: `/requisites/${id}`,
            method: 'DELETE',
        })
    }

    createRequisites = async (requestDTO: RequisitesUpdateRequestDTO): Promise<TraderRequisitesDTO> => {
        let resp = await axios({
            url: `/requisites`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as TraderRequisitesDTO
    }

    createInvoice = async (requestDTO: Object): Promise<InvoiceDTO> => {
        let resp = await axios({
            url: `/invoices`,
            data: requestDTO,
            method: 'POST',
        })

        return resp.data as InvoiceDTO
    }

    tokenGet = async (credentials: any): Promise<AccessTokenDTO> => {
        let resp = await axios({
            url: `/token/get`,
            data: credentials,
            method: 'POST',
            headers: {
                'Authorization': ''
            }
        })

        return resp.data as AccessTokenDTO
    }
}
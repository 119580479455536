import {Module} from "vuex";
import CurrencyMethodsDTO from "@/models/CurrencyMethodsDTO";
import APIClient from "@/service/APIClient";

const api = new APIClient();

const currencyMethods: Module<any, any> = {
    namespaced: true,
    state: {
        list: [] as CurrencyMethodsDTO[],
        loaded: false,
        promise: null,
    },
    actions: {
        async loadList ({ state, commit }) {
            let promise = api
                .requisitesCurrencyMethods()
                .then(resp => {
                    commit('SET_LIST', resp);
                }).finally(() => {
                commit('SET_PROMISE', null);
            })

            commit('SET_PROMISE', promise);

            return promise;
        },
    },
    mutations: {
        SET_LIST: (state, payload: CurrencyMethodsDTO[]) => {
            state.list = payload;
            state.loaded = true;
        },
        SET_PROMISE: (state, payload) => {
            state.promise = payload;
        },
    },
    getters: {
        currencies: (state): string[] => state.list.map((value: CurrencyMethodsDTO) => value.currency),
        methods: (state) => (currency: string|null = null): string[] => {
            if (!currency) {
                let methods = [];
                for (const item of state.list) {
                    for (const method of item.methods) {
                        if (methods.indexOf(method) === -1) {
                            methods.push(method);
                        }
                    }
                }

                return methods;
            }

            let currencyMethods = state.list.find((item: CurrencyMethodsDTO) => item.currency === currency);
            if (!currencyMethods) {
                return []
            }

            return currencyMethods.methods
        },
        list: (state): CurrencyMethodsDTO[] => state.list,
        loaded: (state) => state.loaded,
    }
}

export default currencyMethods;

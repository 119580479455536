<template>
  <div id="app">
    <Menu v-if="this.isLoggedIn"></Menu>
    <div class="pl-2 pr-2">
      <router-view/>
    </div>
    <notifications class="m-3"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from 'axios';
import Menu from './components/Menu';

export default {
  name: "App",
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'getAuth',
      'getUser',
    ]),
  },
  components: {
    Menu,
  },
  methods: {

    setupAxios: function () {
      let self = this;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.getAuth.token
      let auth = {
        isRefreshing: false,
        refreshingCall: undefined,
      }

      function refreshToken(cb) {
        if (auth.isRefreshing) {
          return auth.refreshingCall.then(cb);
        }

        auth.isRefreshing = true;

        const refreshToken = self.getAuth.refreshToken;
        auth.refreshingCall = axios.post('/token/refresh', {refreshToken: refreshToken}, {headers: {Authorization: ""}})
          .then(tokenRefreshResponse => {
            self.$store.commit('authenticate', tokenRefreshResponse.data);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token
            auth.isRefreshing = false;
            auth.refreshingCall = undefined;
            return Promise.resolve(tokenRefreshResponse.data.token);
          }).then(cb);
        return auth.refreshingCall;
      }

      axios.interceptors.response.use(
        (response) => response,
        async (error) => {
          const request = error.config;
          if (
            request &&
            request.url === '/token/refresh' &&
            request.method === 'post' &&
            error &&
            error.response &&
            error.response.status === 401
          ) {
            self.$store.commit('removeAuth');
            window.location.reload();
            return;
          }

          if (error &&
            error.response &&
            error.response.status === 401) {
            return refreshToken(() => {
              error.config.headers['Authorization'] = 'Bearer ' + this.getAuth.token
              return axios.request(error.config);
            });
          }

          throw error;
        }
      );


    }
  },
  async created() {
    if (this.isLoggedIn) {
      this.setupAxios();
      await this.$store.dispatch('loadUser');
    }
  },
  watch: {
    async isLoggedIn(newValue, oldValue) {
      if (newValue) {
        this.setupAxios();
        await this.$store.dispatch('loadUser');
      }
    }
  }
}
</script>


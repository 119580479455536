import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import BootstrapVue3 from 'bootstrap-vue-3'
import VueAxios from 'vue-axios'
import axios from 'axios'
import "./assets/css/bootstrap.min.css";
import 'v-calendar/dist/style.css';
import "./assets/css/styles.css";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "vue-multiselect/dist/vue-multiselect.css"
import "./assets/css/multiselect.css";
import Notifications from '@kyvg/vue3-notification'
import { vMaska } from "maska"
import {helper} from "./service/CommonHelper";
import {RouteMeta} from "vue-router";
import VueQrcode from '@chenfengyuan/vue-qrcode';

helper.setTranslations(require('@/i18n/ru.json'), require('@/i18n/en.json'))
helper.setPhoneCodes(require('@/assets/phonecodes.json'))
axios.defaults.baseURL = window.API_URL;
axios.defaults.headers.common = {
  'Content-Type': 'application/json;charset=UTF-8',
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      if (to.meta.roles.indexOf(store.getters.getRole) === -1) {
        next('/login');
        return;
      }

      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

createApp(App)
    .directive("maska", vMaska)
    .use(VueAxios, axios)
    .use(BootstrapVue3)
    .use(Notifications)
    .use(store)
    .use(router)
    .component(VueQrcode.name, VueQrcode)
    .mount('#app')

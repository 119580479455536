<template>
  <section>
    <div v-if="!isLoggedIn" class="container d-flex flex-column">
      <div class="row align-items-center justify-content-center min-vh-100">
        <div class="col-md-6 col-lg-5 col-xl-4 py-6 py-md-0">
          <div>
            <div class="mb-4 text-center"><h6 class="h3 mb-1 text-capitalize-first">{{ trans('login.log_in') }}</h6>
              <p class="text-muted mb-0">{{ trans('login.login_to_continue') }}</p></div>
            <span class="clearfix"></span>
            <form method="post" name="login-form-merchant-p2p">
              <div class="form-group"><label class="form-control-label text-capitalize-first">{{ trans('labels.login') }}</label>
                <div class="input-group input-group-merge">
                  <input type="text"
                         v-model="username"
                         class="form-control form-control-prepend"
                         id="input-username"
                         name="username" required
                         :placeholder="trans('labels.login')"
                  >
                  <div class="input-group-prepend"><span class=""><svg
                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user"><path
                    d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle
                    cx="12" cy="7" r="4"></circle></svg></span></div>
                </div>
              </div>
              <div class="form-group mb-0 mt-2">
                <div class="d-flex align-items-center justify-content-between">
                  <div><label class="form-control-label text-capitalize-first">{{ trans('labels.password') }}</label></div>

                </div>
                <div class="input-group input-group-merge">
                  <input type="password"
                         v-model="password"
                         class="form-control form-control-prepend"
                         id="input-password"
                         name="password"
                         :placeholder="trans('labels.password')">
                  <div class="input-group-prepend"><span class=""><svg
                    xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
                    viewBox="0 0 24 24" fill="none" stroke="currentColor"
                    stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-key"><path
                    d="M21 2l-2 2m-7.61 7.61a5.5 5.5 0 1 1-7.778 7.778 5.5 5.5 0 0 1 7.777-7.777zm0 0L15.5 7.5m0 0l3 3L22 7l-3-3m-3.5 3.5L19 4"></path></svg></span>
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <button @click.prevent="doLogin" type="submit" class="btn w-100 btn-primary text-capitalize-first">{{trans('button.enter')}}</button>
              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {mapGetters} from 'vuex';
import {useNotification} from "@kyvg/vue3-notification";
import {UserRole} from "@/models/enum/UserRole";
import {helper} from "@/service/CommonHelper";

const redirectTo = (role) => {
  switch (role) {
    case UserRole.TRADER:
    case UserRole.MERCHANT:
      return '/';
    case UserRole.OPERATOR:
      return '/deals';
    case UserRole.ADMIN:
      return '/admin/accounts';
    default:
      return '/';
  }
}

const notification = useNotification()
export default {
  name: 'Login',
  components: {
  },
  methods: {
    trans: function (a, b) {
        return helper.trans(a, b)
    },
    doLogin: function () {
      let username = this.username;
      let password = this.password;

      this.$store.dispatch('login', {
        username,
        password,
      }).catch((error) => {
        if (error.response && error.response.status === 401) {
          notification.notify({
            title: helper.trans('login.errors.invalid_credentials'),
            type: 'warn'
          });
        } else {
          notification.notify({
            title: helper.trans('login.errors.auth_error'),
            type: 'warn'
          });
        }
        throw error;
      }).then(async () => {
        await this.$store.dispatch('loadUser');
        this.$router.push(redirectTo(this.getRole));
      })
    },
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
      'isTrader',
      'isOperator',
      'getRole'
    ]),
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push(redirectTo(this.getRole));
    }
  },
  watch: {},
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push(redirectTo(this.getRole));
    }
  },
  data() {
    return {
      username: '',
      password: '',
    };
  },
};
</script>

import {reactive} from "@vue/reactivity";
import ValidationErrorDTO from "@/models/ValidationErrorDTO";

class CommonHelper {
  private translations: any = {};
  private fallbackTranslations: any = {};
  private phoneCodes: object[] = []

  private copyState = reactive({
    timeouts: {} as any,
  });

  public currencyIcon(currency:string) {
    if (currency === 'USDT') {
      return require(`@/assets/img/currencies/USDT_TRC20.svg`);
    }
    try {
      return require(`@/assets/img/currencies/${currency}.svg`);
    } catch (e) {
      return require(`@/assets/img/currencies/unknown.svg`);
    }

  }

  public flattenObject(obj: any, prefix = '', res: any = {}) {
    for (let key in obj) {
      let newKey = prefix ? `${prefix}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        this.flattenObject(obj[key], newKey, res);
      } else {
        res[newKey] = obj[key];
      }
    }
    return res;
  }

  public setPhoneCodes(items: Object)
  {
    this.phoneCodes = [];
    for (let pattern of Object.values(items)) {
      let filteredPattern = (pattern as string).replace(/\D/g, '');
      this.phoneCodes.push({
        pattern,
        filteredPattern
      })
    }
  }

  public getPhonePattern(phone: string): string|null {
    let filteredPhone = phone.replace(/\D/g, '');
    for (let code of this.phoneCodes) {
      if (filteredPhone.startsWith((code as any).filteredPattern)) {
        return (code as any).pattern
      }
    }

    return null;
  }

  public generateRandomString(len: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';

    for (let i = 0; i < len; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomString += characters.charAt(randomIndex);
    }

    return randomString;
  }

  public setTranslations(items: Object, fallback: Object)
  {
    this.translations = this.flattenObject(items);
    this.fallbackTranslations = this.flattenObject(fallback);
  }

  public trans = (key: string, params: any = {}, fallback: string|null = null) => {
    let msg = this.translations[key] ?? this.fallbackTranslations[key] ?? fallback ?? key;

    if (params) {
      for (let paramName in params) {
        msg = msg.replaceAll(`{${paramName}}`, params[paramName])
      }
    }

    return msg;
  }

  public payMethodIcon = (method: string): any => {
    try {
      const icon = require(`@/assets/img/methods/${method}.png`);

      return icon ? icon : require(`@/assets/img/methods/unknown_method.svg`);
    } catch (e) {
      return require(`@/assets/img/methods/unknown_method.svg`);
    }
  }

  private fallbackCopyTextToClipboard = (obj: any, text: string) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";
    obj.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      obj.removeChild(textArea);
      return successful;

    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    obj.removeChild(textArea);
    return 0
  }

  public initCopyHints = (): void => {
    this.copyState.timeouts = {};
  }

  public copyHintVisible = (hint: string): boolean => {
    return !!this.copyState.timeouts[hint];
  }

  public copy = (obj: any, value: string, hint: string): void => {
    helper.copyTextToClipboard(obj, value);
    if (this.copyState.timeouts[hint]) {
      clearTimeout(this.copyState.timeouts[hint])
    }
    this.copyState.timeouts[hint] = setTimeout(() => {
      this.copyState.timeouts[hint] = null;
    }, 1000);
  }

  public copyTextToClipboard = (obj: any, text: string) => {
    if (!navigator.clipboard) {
      return this.fallbackCopyTextToClipboard(obj, text);
    }
    navigator.clipboard.writeText(text).then(function() {
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  }

  isValidationErrors(e: any): boolean {
    return e.response && e.response.status === 400 && e.response.data;
  }

  getValidationErrors(e: any): ValidationErrorDTO[] {
    if (this.isValidationErrors(e)) {
      return e.response.data as ValidationErrorDTO[]
    }

    return [];
  }
}

export const helper = new CommonHelper();
export const trans = helper.trans;

